const http = require('./http');
const authToken = localStorage.getItem("authToken");

const getNumberManagerListSize = (payload) => {
    return new Promise((resolve, reject) => {
        http.post(`/getNumberManagerListSize`, payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getDidNumberDetailSubscriptionNew = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getDidNumberDetailSubscriptionNew?viewIndex=${payload.viewIndex}&viewSize=${payload.viewSize}&partyId=${payload.partyId}&didStatus=${payload.didStatus}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getCustomDidNumberDetailSubscriptionNew = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getDidNumberDetailSubscriptionNew?partyId=${payload.partyId}&didStatus=${payload.didStatus}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setBillingTenure = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('setBillingTenure', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getDeviceList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getDeviceList?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getDeviceDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getDeviceDetails?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}&deviceId=${payload.deviceId}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getFaxboxesList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getFaxboxesList?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteDeviceDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.delete(`/deleteDeviceDetails?accountId=${payload.accountId}&deviceId=${payload.deviceId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteFaxboxesDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.delete(`/deleteFaxboxesDetails?accountId=${payload.accountId}&faxboxesId=${payload.faxboxesId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getDIDRenewTotalCost = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getDIDRenewTotalCost?inventoryItemId=${payload.inventoryItemId}&orderId=${payload.orderId}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const runSubscriptionExpired = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/runSubscriptionExpired?subscriptionId=${payload.subscriptionId}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getForwardDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getForwardDetails?accountId=${payload.accountId}&inventoryItemId=${payload.inventoryItemId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getFilteredDeviceList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getFilteredDeviceList?accountId=${payload.accountId}&forwardType=${payload.forwardType}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};


const assignCellPhone = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('assignCellPhone', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const assignDidToServers = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('assignDidToServers', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};


const assignSip = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('assignSip', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const addConnectivityServers = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('addConnectivityServers', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const assignFaxBox2Email = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('assignFaxBox2Email', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const removeServerForward = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('removeServerForward', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteCallFlow = (payload) => {
    return new Promise((resolve, reject) => {
        http.delete(`/deleteCallFlow?accountId=${payload.accountId}&didNumber=${payload.didNumber}&authToken=${payload.authToken}&inventoryItemId=${payload.inventoryItemId}&callflowId=${payload.callflowId ? payload.callflowId : ""}`).then((data) => {
            resolve(data);
            this
        }).catch((error) => {
            reject(error);
        });
    });
};

const terminateDID = (payload) => {
    return new Promise((resolve, reject) => {
        http.delete(`/terminateDID?accountId=${payload.accountId}&inventoryItemId=${payload.inventoryItemId}&authToken=${localStorage.getItem("authToken")}&callflowId=${payload.callflowId ? payload.callflowId : ""}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const terminateNumberDelayed = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('terminateNumberDelayed', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const autoRenewSubscription = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('/autoRenewSubscription', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};


const terminateNumbersImmediate = (payload) => {
    return new Promise((resolve, reject) => {
        http.post(`terminateNumbersImmediate`, payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const terminateNumbersDelayed = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('terminateNumbersDelayed', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const updateBillingTenure = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('updateBillingTenure', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const updatedEmailForSms = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('updatedEmailForSms', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const updateConnectivityServers = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('updateConnectivityServers', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteConnectivityServers = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('deleteConnectivityServers', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

module.exports = {
    getNumberManagerListSize,
    getDidNumberDetailSubscriptionNew,
    getCustomDidNumberDetailSubscriptionNew,
    setBillingTenure,
    getDeviceList,
    getDeviceDetails,
    getFaxboxesList,
    deleteDeviceDetails,
    deleteFaxboxesDetails,
    getDIDRenewTotalCost,
    runSubscriptionExpired,
    getForwardDetails,
    getFilteredDeviceList,
    assignCellPhone,
    deleteCallFlow,
    assignDidToServers,
    addConnectivityServers,
    removeServerForward,
    assignFaxBox2Email,
    assignSip,
    terminateDID,
    terminateNumberDelayed,
    autoRenewSubscription,
    terminateNumbersDelayed,
    terminateNumbersImmediate,
    updateBillingTenure,
    updatedEmailForSms,
    updateConnectivityServers,
    deleteConnectivityServers
};